.chart_label {
  font-size: 0.777rem;
}
.chart-value {
  font-size: 2.8rem;
  font-family: 'font-light';
}

.Card {
  border: 1px solid #d3daea;
  border-radius: 10px;
}
