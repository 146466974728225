@import '../../assets/scss/variables';

.DashboardPage {
  .main-tabs.nav-pills {
    .nav-item {
      padding-right: 2.5rem;
    }

    .nav-link {
      background-color: transparent;
      color: #000;
      font-size: 1.2rem;
      padding: 4px 6px 4px;
      border-radius: 0;
      font-family: 'font-medium';
      &.active {
        border-bottom-width: 3px;
        border-bottom-style: solid;
        border-bottom-color: #328e69;
      }
    }
  }

  .card {
    border: 0;
    border-radius: 8px;
  }
  .section-title {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .section-subtitle {
    margin: 12px 3px;
  }

  .filter-btns {
    button {
      height: $input-height;
      min-width: 77px;
      border-radius: 8px !important;
    }
  }

  .paiChartTitle {
    text-align: center;
    color: #4d4f5c;
    opacity: 1;
    position: absolute;
    top: 40%;
    left: 26%;
    p {
      font-size: 2.8rem;
      margin: 0px;
      line-height: 40px;
    }
  }

  .greyCard {
    background: #f8faff 0% 0% no-repeat padding-box;
    border: 1px solid #d3daea;
    border-radius: 10px;
    opacity: 1;
    width: auto;
    padding: 2.3rem;
    // min-height: 300px;
    height: 100%;
    // align-items: center;
    // display: flex;
  }

  .valueCardHeight {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .materialCardHeight {
    // height: 270px;
    // height: 240px;
  }

  .logo {
    background: #ffe4c9 0% 0% no-repeat padding-box;
    width: 65px;
    height: 65px;
    margin-bottom: 1.5rem;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 13px;

    border-radius: 10px;
    text-align: center;
    img {
      width: 25px;
    }
  }

  .cash_balance_logo {
    background: #d3fffb 0% 0% no-repeat padding-box;
  }

  .card_title {
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 1.321rem;
    font-family: 'font-regular';
  }

  .card_value {
    color: #4d4f5c;
    opacity: 1;
    text-align: left;
    letter-spacing: 0px;
    font-size: 2.2rem;
    font-family: 'font-bold';
    overflow-wrap: normal;
    line-height: 40px;
  }

  .mt {
    color: #4d4f5c;
    opacity: 1;
    text-align: left;
    letter-spacing: 0px;
    font-size: 1.4rem;
    font-family: 'font-bold';
  }

  .sale_subheading {
    font-size: 0.9rem;
  }

  .card_subtitle {
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 1.1111rem;
    margin-bottom: 1px;
  }

  .card_subtitle_value {
    color: #4d4f5c;
    opacity: 1;
    text-align: left;
    font-size: 1.844rem;
    font-family: 'font-bold';
    letter-spacing: 0px;
    display: flex;
    align-items: baseline;
    // word-spacing: 12px;
    // line-height: 32px;
  }

  .mt_sub {
    color: #4d4f5c;
    opacity: 1;
    text-align: left;
    font-size: 1.1rem;
    font-family: 'font-bold';
    letter-spacing: 0px;
  }

  .legend_line {
    width: 34px;
    border-radius: 4px;
    margin-bottom: 6px;
  }

  .bg-info {
    background-color: #edf0f5 !important;
  }

  .myProgress {
    border-radius: 8px !important;
    height: 10px !important;
  }

  .receivable_label {
    text-align: left;
    font-size: 1.3rem;
    letter-spacing: 0px;
    color: #000000;
    padding-right: 5px;
  }

  .receivable_value {
    text-align: left;
    font-family: 'font-bold';
    font-size: 1.6rem;
    letter-spacing: 0px;
    color: #000000;
  }

  .attention_card {
    background: #f8faff 0% 0% no-repeat padding-box;
    border: 1px solid #d3daea;
    border-radius: 10px;
    opacity: 1;
    height: 60px;
    margin-top: 15px;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
  }

  .attention_value {
    font-size: 1.6rem;
  }

  .attention_label {
    font-size: 1rem;
    margin-left: 8px;
    line-height: 1rem;
    font-family: 'font-medium';
  }

  .date-range-field {
    background-image: url('../../assets/img/date_icon.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    max-width: 100%;
    background-color: #fff !important;
    font-weight: normal;
    background-origin: content-box;
    border-color: #dedede;
    background-position: right center;
    border-radius: 8px !important;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000000;
      opacity: 0.4;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .form-control {
    border-radius: 8px !important;
  }

  .react-select {
    .select__control {
      border-radius: 8px !important;
    }
  }

  .top5-card-height {
    min-height: 19rem;
  }

  .top-fixed {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .popover-div {
    background-color: #e5f4ff;
    padding: 1rem 1rem 1rem 0;
    border: none;
    border-radius: 10px;
    margin-top: 0.7rem;
  }

  .triangle-cap {
    background-color: #e5f4ff;
    height: 30px;
    width: 34px;
    clip-path: polygon(50% 0%, 0 100%, 100% 100%) !important;
  }

  @media screen and (max-width: 480px) {
    // .paiChartTitle {
    //   top: 42%;
    //   left: 28%;
    //   p {
    //     font-size: 1.944rem;
    //     line-height: 22px;
    //   }
    // }
    .filter-btns {
      button {
        min-width: 45%;
        height: $input-height-mobile !important;
        padding: 0px;
      }
    }
    .form-control {
      height: $input-height-mobile !important;
    }
    .react-select {
      .select__control {
        min-height: $input-height-mobile !important;
      }
    }
    h4 {
      font-size: 2rem !important;
    }
    .card_value {
      font-size: 2rem;
    }
    .receivable_label {
      font-size: 1.1rem;
    }
    .receivable_value {
      font-size: 1.2rem;
    }
    // .materialCardHeight,
    // .valueCardHeight {
    //   height: 190px;
    // }
    .chart_label {
      font-size: 0.777rem;
    }
    .chart-value {
      font-size: 2.8rem;
      font-family: 'font-light';
    }
    // .logo {
    // width: 50px;
    // height: 40px;
    // }
    .sale_subheading {
      font-size: 0.7rem;
    }
    .mt {
      font-size: 1rem;
    }
    // .greyCard {
    //   background: #fff;
    //   border: 1px solid #fff;
    // }
    .btn {
      font-size: 1.2rem;
    }
  }
}
.smartscan {
  .card {
    border: 1px solid #d3daea;
    border-radius: 10px;
  }
}
